import { useState, useMemo, useLayoutEffect, useCallback, useRef } from 'react';
import {
  Box,
  Card, Page,
  TextField
} from '@shopify/polaris';
import { is, ok, schema, TABLE_NAMES } from "common";
import { DataService } from "data-service";
import React from "react";
import { ListTablePage } from "./page-table-list";
import { Dashboard } from './page-branch-dashboard';
import { PageBranchUnitsGrid } from './page-branch-unitsgrid';
import { NgZone } from '@angular/core';
import { CustomerLayoutPage } from './page-customer-edit';
import { CustomerTableList } from "./page-customer-list";
import { useAngular, useDispatch, useRoute } from "react-utils";
import { BranchLedgerPage } from './page-branch-ledger';
import { PageBranchRentals } from './page-branch-rentals';
import { PermissionsPage } from './page-permissions';
import { AppTesting } from './page-testing';
import { EditTablePage } from './page-table-edit';
import { TableView, TableViewClass } from '../tables/table-views';
import { SelectDropdown } from '../components/Select';
import { TableListSimple } from '../tables/TableListInner';
import { PageBranchSalesTax } from './page-branch-salestax';
import { PageSystemLedger } from './page-system-ledger';
import { StripeRefreshPage, StripeReturnPage } from './page-stripe';
import { useNavigate } from "react-router-dom";
import { Router } from '@angular/router';
import { StripeArlenPage } from './page-arlen-stripe';
import { AutopayArlenPage } from './page-arlen-autopay';
import { AutopayBranchPage } from './page-branch-autopay';
import { PageCentralReport } from './page-central-report';

export function AppLayoutBody() {
  const { get } = useAngular();
  const data = get(DataService);
  const router = get(Router);
  const zone = get(NgZone);

  const navigate = useDispatch(useNavigate());

  if (!data.status)
    throw data.ready;

  const { table, view2: view, id } = useRoute();

  useLayoutEffect(() => {
    if (!table) navigate("/Branch/dashboard");
  }, [table, navigate]);

  if (!table) return null;

  if (table === "Testing")
    return <AppTesting />;

  if (table === "Arlen" && view === "stripe")
    return <StripeArlenPage />;

  if (table === "Arlen" && view === "autopay")
    return <AutopayArlenPage />;

  if (table === "Central")
    return <PageCentralReport />;

  if (table === "System" && view === "ledger")
    return <PageSystemLedger />;

  ok(is<TABLE_NAMES>(table, !!schema.tables[table]));

  if (table === "Permission" && view === "list")
    return <PermissionsPage key="PermissionsPage" />;

  if (table === "Branch" && view === "customers" || table === "Customer" && view.startsWith("list"))
    return <CustomerTableList key="CustomerTableList" />;
  if (table === "Branch" && view.startsWith("ledger"))
    return <BranchLedgerPage key="BranchLedger" />;
  if (table === "Branch" && view.startsWith("rental"))
    return <PageBranchRentals key="PageBranchRentals" />;
  if (table === "Branch" && view === "sales-tax")
    return <PageBranchSalesTax key="PageBranchSalesTax" />;
  if (table === "Branch" && view === "units")
    return <PageBranchUnitsGrid key="BranchUnits" />;
  if (table === "Branch" && view === "dashboard")
    return <Dashboard key="BranchDashboard" />;
  if (table === "Branch" && view === "autopay")
    return <AutopayBranchPage key="AutopayBranchPage" />;

  if (table === "Customer")
    return <CustomerLayoutPage key={table + id} />;

  if (view.startsWith("list") || view.startsWith("ledger"))
    return <ListTablePage key={table} />;


  switch (view) {
    case "edit":
    case "add":
    case "payment-method":
    case "make-payment":
      return <EditTablePage key={table + view + id} />;
    case "stripe-refresh":
      return <StripeRefreshPage key="StripeRefreshPage" />;
    case "stripe-return":
      return <StripeReturnPage key="StripeReturnPage" />;

  }

  return null;

}
